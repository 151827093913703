<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        For each of the following scenarios indicate if the volume needed is an
        <b>accurate</b> value (the volume is needed should have as many significant figures as
        possible) or an <b>approximate</b> volume (the volume is just an estimate).
      </p>
      <ol>
        <li v-for="question in questions" :key="question.inputName">
          {{ question.text }}
          <v-select
            v-model="inputs[question.inputName]"
            style="display: inline-block; max-width: 300px"
            class="my-1"
            :items="options"
            item-text="text"
            item-value="value"
            label="Select an option:"
          >
            <template #item="{item}">
              <stemble-latex class="no-text-transform" :content="item.text" />
            </template>
            <template #selection="{item}">
              <stemble-latex class="no-text-transform" :content="item.text" />
            </template>
          </v-select>
        </li>
      </ol>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S2_Q6',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      questions: [
        {text: '10 mL of buffer needed to calibrate the pH probe', inputName: 'input1'},
        {
          text: '10 mL volume of solution needed to create a standard solution',
          inputName: 'input2',
        },
        {
          text: "10 mL of acetic acid needed to find acetic acid's pH with a pH probe",
          inputName: 'input3',
        },
        {
          text: '1 mL of sodium acetate needed to form a 9:1 acetic acid:acetate buffer',
          inputName: 'input4',
        },
        {text: 'the amount of titrant dispensed from a buret', inputName: 'input5'},
        {text: '1/4 inch of sodium bicarbonate to neutralize lab waste', inputName: 'input6'},
      ],
      options: [
        {text: 'accurate', value: 'accurate'},
        {text: 'approximate', value: 'approximate'},
      ],
    };
  },
};
</script>
